<template>
    <CRow>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars" />
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader class="text-center bg-dark text-white">
                    <b>{{$t('label.berthList')}}</b>
                </CCardHeader>
                <CCardBody>
                    <app-modal-muelle />
                    <CRow>
                        <CCol col="12" xl="12">
                            <CCol sm="12">
                                <CRow>
                                    <CCol sm="12" class="d-flex align-items-center justify-content-end">
                                        <CButton
                                            color="add"
                                            v-c-tooltip="{
                                                content: $t('label.berth'),
                                                placement: 'top-end'
                                            }"
                                            @click="activarModal()"
                                        >
                                            <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                                        </CButton>
                                    </CCol>
                                    <CCol v-if="!PortId" sm="12" lg="6">
                                        <div class="form-group form-row" rol="group">
                                            <label class="col-form-label col-sm-3 text-right" 
                                            for="labelPuerto">{{$t('label.port')}}</label>
                                            <div class="col-sm-9 input-group-sm">
                                                <v-select @input="setPuertoSelected" :value="$store.state.muelle.filtroPuerto" :options="optionsDataPuertos" :reduce="option => option.value" :filter="fuseSearch" :getOptionLabel="option => option.title"  >
                                                    <template #no-options="{ }">
                                                       {{ $t('label.noSearchData') }}
                                                    </template>
                                                    <template #option="{ Json, title }">
                                                        {{ title }}
                                                        <br />
                                                        <cite>{{ Json.CodIsoAlpha3 }} - {{ Json.NbPais }} </cite>
                                                    </template>
                                                </v-select>
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>
                                <dataTableExtended
                                    class="align-center-row-datatable"
                                    hover
                                    sorter
                                    small
                                    column-filter
                                    :table-filter="tableText.tableFilterText"
                                    :items-per-page-select="tableText.itemsPerPageText"
                                    :items="formatedItems"
                                    :fields="fields"
                                    :noItemsView="tableText.noItemsViewText"
                                    :items-per-page="5"
                                    pagination
                                    :loading="apiStateLoading"
                                >
                                    <template #loading>
                                        <loading/>
                                    </template>
                                    <template #BerthId="{ index }">
                                        <td class="center-cell text-center" >
                                            {{index+1}}
                                        </td>
                                    </template>
                                    <template #BerthName="{ item }">
                                        <td class="center-cell" >
                                            {{item.BerthName}}
                                        </td>
                                    </template>
                                    <template #PortCode="{ item }">
                                        <td class="center-cell text-center" >
                                            {{item.PortCode}}
                                        </td>
                                    </template>
                                    <template #CountryName="{ index,item }">
                                        <td class="center-cell">
                                            <CRow>
                                                <CCol sm="8">
                                                    {{item.CountryName}}
                                                </CCol>
                                                <CCol sm="4">
                                                    <img :src="srcImage(item.Icon)" :alt="item.CountryName+index" width="50px" height="50px" class="img-thumbnail icon mb-2" @error="setAltImg" />
                                                </CCol>
                                            </CRow>
                                        </td>
                                    </template>
                                    <template #LineDock="{ item }">
                                        <td class="center-cell text-center" >
                                            {{item.LineDock}}
                                        </td>
                                    </template>
                                    <template #Draft="{ item }">
                                        <td class="center-cell text-center" >
                                            {{item.Draft}}
                                        </td>
                                    </template>
                                    <template #Operativo="{ item }">
                                        <td class="center-cell text-center" >
                                            {{item.Operativo}}
                                        </td>
                                    </template>
                                    <template #UltimoUsuario="{ item }">
                                        <td class="center-cell text-justify" >
                                            {{item.UltimoUsuario}}
                                        </td>
                                    </template>
                                    <template #FormatedDate="{ item }">
                                        <td class="center-cell text-center" >
                                            {{item.FormatedDate}}
                                        </td>
                                    </template>

                                    <template #Status="{item}">
                                        <td class="center-cell text-center">
                                            <CBadge v-if="item.status" color="success">
                                                {{ $t('label.'+item.Status) }}
                                            </CBadge>
                                            <CBadge v-if="!item.status" color="danger">
                                                {{ $t('label.'+item.Status) }}
                                            </CBadge>
                                        </td>
                                    </template>
                                    <template #acciones="{item, index}">
                                        <td class="center-cell text-center">
                                            <CButton
                                                color="edit"
                                                size="sm"
                                                class="mr-1"
                                                v-c-tooltip="{
                                                    content: $t('label.edit')+$t('label.berth'),
                                                    placement: 'top-end'
                                                }"
                                                @click="updateModal(item, index)"
                                            >
                                                <CIcon name="pencil"/>
                                            </CButton>
                                        </td>
                                    </template>z
                                </dataTableExtended>
                            </CCol>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import Fuse from "fuse.js";
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import appModalMuelle from './app-modal-muelle.vue';
    import GeneralMixin from '@/_mixins/general'

    const dataPais = [];
    function fields(){
        return [
            { key: 'BerthId', label: '#',filter :false, _style:'width: 5%; text-align:center;' },
            { key: 'BerthName',label: this.$t('label.berth'), _style:'width: 17%;' },
            { key: 'PortCode',label: this.$t('label.portCode'), _style:'width: 5%; text-align:center;' },
            { key: 'CountryName',label: this.$t('label.country'), _style:'width: 20%;' },
            { key: 'LineDock',label: this.$t('label.berthLine')+' (MTR)', _style:'width: 12%;text-align:center;' },
            { key: 'Draft',label: this.$t('label.draft')+' (MTR)', _style:'width: 5%;text-align:center;' },
            { key: 'Operativo',label: this.$t('label.operational'), _style:'width: 8%;text-align:center;' },
            { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width: 5%;'},
            { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 8%; text-align:center;'},
            { key: 'Status', label: this.$t('label.status'), _style:'width:5%; text-align:center;'},
            { 
                key: 'acciones', 
                label: '', 
                _style: 'width:50px; text-align:center;', 
                sorter: false, 
                filter: false
            }
        ];
    }

    //data
    function data() {
        return {
            srcError:'/img/errorimage.jpg',
            activePage: 1,
            
        }
    }

    //methods
    function activarModal(){
        this.$store.state.muelle.BerthRoute = '';
        this.$store.commit('muelle/mutationModal', true);
    }
    function updateModal(item,index){
        this.$store.state.muelle.BerthRoute = item.BerthRoute;
        this.$store.commit('muelle/mutationModal', true);
        this.$store.commit('muelle/asignarid', item.BerthId);
    }
    function setRefresh(){
        this.$store.dispatch('muelle/getMuellelist', {filtro:'ALL', IdPuerto: this.PortId});
    }
    function srcImage(icon){
        if(icon === null || icon === '' || icon === undefined){
            return this.srcError;
        }else{
            return icon;
        }
    }
    function setAltImg(event){
        event.target.src = this.srcError;
    }
    function fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["title", "Json.NbPais", "Json.CodIsoAlpha3"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    }
    function setPuertoSelected(value){
        this.$store.state.muelle.filtroPuerto = value;
    }

    //mounted
    function mounted() {
        this.$store.state.muelle.apiState = ENUM.LOADED;
        this.$store.commit('muelle/mutationModal', false);
        if (this.PortId) {
            this.$store.dispatch('muelle/getMuellelist', {filtro:'ALL', IdPuerto: this.PortId});
        }else{
            this.$store.dispatch('muelle/getPuertolist',{opcion:1, IdPuerto: this.PortId});
        }
    }
    
    //created
    function created() {
        
    }

    //computed
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function computedItems () {
        return this.$store.getters["muelle/myDatatable"];
    }
    function formatedItems() {
        return this.computedItems
    }
    function optionsListPuertos(){
        if(this.myDataPuerto.length === 0){
            return [{
                title: this.$t('label.select'),
                value: null,
                Json: {
                    NbPais: '',
                    CodIsoAlpha3: ''
                },
            }];
        }else{
            const chart = [{
                title: this.$t('label.select'),
                value: null,
                Json: {
                    NbPais: '',
                    CodIsoAlpha3: ''
                },
            }];
            try{
                const datos = this.myDataPuerto;
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    chart.push({
                        title: element.PortName,
                        value: element.PortId,
                        Json: {
                            NbPais:element.CountryJson[0].CountryName,
                            CodIsoAlpha3: element.CountryJson[0].IsoCodeAlpha3
                        },
                    })
                }
                return chart;
            }catch(error){
                return chart;
            }
        }
    }
    //watch
    function apiState(newQuestion,OldQuestion){
        if(newQuestion === ENUM.ERROR){
            if(this.apiStateForm !== ENUM.ERROR){
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' :  this.messageError)
                });
                this.$store.state.muelle.messageError = '';
            }
        }
    }
    function apiStateForm(newQuestion,OldQuestion){
        switch (newQuestion) {
            case ENUM.ERROR:
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' : this.messageError)
                });
                this.$store.state.muelle.messageError = '';
                break;
            case ENUM.LOADED:
                this.$notify({
                    group: 'container',
                    type: 'success',
                    ignoreDuplicates:false,
                    title: '¡Éxito!',
                    text: (this.messageError === '' ? 'Su proceso ha finalizado con éxito' : this.messageError)
                });
                this.$store.state.muelle.messageError = '';
                break;
            default:
                break;
        }
    }
    function busqueda(newValue,OldValue){
        if(newValue === '' || newValue === null){
            this.$store.dispatch('muelle/getMuellelist',{filtro:'ALL',IdPuerto:''});
        }else{
            const IdPuerto = newValue;
            this.$store.dispatch('muelle/getMuellelist',{filtro:'ALL',IdPuerto});
        }
    }

    export default{
        name: 'app-muelle',
        data,
        mixins:[GeneralMixin],
        components: {
            appModalMuelle
        },
        methods: {
            activarModal,
            updateModal,
            srcImage,
            setAltImg,
            setRefresh,
            fuseSearch,
            setPuertoSelected,
        },
        mounted,
        computed: {
            optionsListPuertos,
            optionsDataPuertos() {
                return this.optionsListPuertos
            },
            computedItems,
            formatedItems,
            apiStateLoading,
            apiStateFormLoading,
            fields,
            ...mapState({
                myDataPuerto : state=> state.muelle.myDataPuerto,
                filtroPuerto : state=> state.muelle.filtroPuerto,
                filtroMuelle : state=> state.muelle.filtroMuelle,
                messageError : state=> state.muelle.messageError,
                apiState: state => state.muelle.apiState,
                apiStateForm: state => state.muelle.apiStateForm,
                PortId: state => state.auth.branch.PortId,
            })
        },
        watch:{
            apiState,
            apiStateForm,
            filtroPuerto: {
                deep:true,
                handler:busqueda
            }
        }
    }
</script>

<style scoped>
    .center-cell {
        vertical-align: middle;
        /*text-align: center;*/
    }
    .icon{
        background-color: null; 
        border: 0px;
    }
</style>